.toursHero{
    height: 70vh;
}

.toursHero img{
    max-height: 100%;
    min-height: 100%;
    width: 100%;
    object-position: center;
    object-fit:cover;
}

.tourPageDetails img{
    width: 100px;
}

span{
    color: #0E6E84;
}

.priceDiv{
   
    text-align: center;
    justify-content: center;
    border: 1px #0E6E84 solid;
    border-radius: 15px;
    justify-content: flex-start;
    width: fit-content;
}

.priceDiv p{
    margin-block: 10px;
    border-right: #0E6E84 2px solid;
    padding-inline: 15px;
}



.priceDiv p:last-child{
    border-right: none;
}

.borderDiv{
    border-right: #0E6E84 2px solid;
}


.tourHr{
    color: #0E6E84;
    border-top: 2px solid #0E6E84;
}

/**/

.paraNav {
    text-align: center;
    border: #0E6E84 1px solid;
    border-radius: 0px 0px 15px 15px;
  }
  
  .paraNav a,
  .paraNav button {
    flex-basis: 25%;
    background-color: #0E6E84;
    color: white !important;
    text-decoration: none;
    justify-self: center;
    padding-block: 10px;
    border-right: white 1px solid;
  }
  
  .paraNav button {
    border-right: none;
    background-color: white;
    color: black !important;
    text-decoration: underline !important;
    border-radius: 0px 0px 15px 0px;
    border: none;
  }
  
  .paraNav a:first-child {
    border-radius: 0px 0px 0px 15px;
  }
  

#my-button {
    opacity: 0;
    position: relative; /* Or relative, depending on your layout */
    right: 100%;
    margin-inline: auto;
    height: 50px;
    scroll-margin: 100%;
    color: white;
  }

  #my-button > button{
    position: relative;
    height: 70%;
    width:150px;
    top:50%;
    transform: translateY(-50%);
    background-color: white;
    border:none;
    padding-inline: 8px;
    border-radius: 10px;
}
  
  #my-button.sticky {
    opacity: 1;
    position: fixed !important;
    right: 0%;
    transition: right 1.5s;
    width:100%;
    text-align: center;
    justify-items: center;
    background-color: rgb(252, 147, 106);
    top: 100%;
    transform: translateY(-100%); /* Target top position after scrolling */
    scroll-margin: 100%;
    z-index: 3;
  }

  #my-button .sticky a{
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    height: 100%;
}

/**/




.articleDiv:not(.last){
    border-right: #0E6E84 2px solid;
}

.last li p{
    margin-bottom: 0px;
}


  
  .articleDiv article {
    padding-right: 10px;
    color: #12424D; /* Can be moved here for all articles within .articleDiv */
  }
  
  .articleDiv li {
    border-left: #0E6E84 2px solid;
    padding-left: 10px;
  }
  
  .articleDiv li span {
    color: rgba(14, 110, 132, 0.67);
    font-size: 12px;
  }
  
  .articleDiv p {
    color: #12424D;
  }
  

.rightDiv li, .articleDiv li{
    list-style: none;
}


  
  .rightDiv ul {
    padding: 10px;
  }
  
  .rightDiv .innerUL1 {
    color: black;
  }
  
  .rightDiv .innerUl {
    background-color: #0E6E84;
    color: white;
    width: fit-content;
    border-radius: 15px;
  }
  
  .rightDiv .innerUl li {
    border-left: #fff 2px solid;
    padding-left: 10px;
    margin-block: 10px;
  }
  
  .rightDiv h5 {
    align-self: start;
  }
  


.benefitsIcons{
    width:20px;
    margin-right:5px;
    filter: invert(29%) sepia(41%) saturate(2518%) hue-rotate(162deg) brightness(90%) contrast(89%); 
}



/***********************************************************************************/

@media only screen and (max-width: 992px){

    .tourPageDetails img{
        width: 70px;
    }

    .articleDiv:not(.last){
        border-right:none; 
    }

    .articleDiv:not(.last) article{
        padding-right: 0px;
    }

    .paraNav{
        display: none !important;
    }
}