.fbMainDiv{
    height: 92%;
    background-image: url(../images/littleAcheron/pickupImg.webp);
    background-position: center;
    background-size: cover;
}

.fbInnerDiv{
    background-color: rgba(14, 110, 132,0.9);
}

.btn{
    background-color: aliceblue;
    border :1px solid rgba(14, 110, 132,1) !important;
}

.btn:hover{
    background-color:rgba(14, 110, 132,1) !important;
    border: 1px solid white !important;
    color: white;
}

.color-white{
    color: white;
}

.step-item{
    height: 5px;
    background-color: rgba(255, 255, 255,0.4);
    border-radius: 20px;
}

.step-item-active{
    background-color: white;
}

.backBtn{
    background-color: transparent;
    border: none;
    transform: scale(1.5);
}

@media only screen and (max-width: 992px){
    .color-white{
        font-size: 20px;
    }
}