.toursMainDiv {
    margin-inline: auto;
    background-color: white;
    color: #0E6E84;
  }
  
  .toursMainDiv .enabledButton {
    background-color: #0E6E84;
    color: white;
    text-decoration: none;
    border-radius: 15px;
    height: fit-content;
    width: 100%;
    border: 1px white solid;
    padding-block: 2px;
  }
  
  .toursMainDiv .bookLinks {
    color: black;
    text-decoration:underline;
  }
  





.priceInfoP{
    margin-bottom: 0px !important;
    font-size: 12px;
    align-self: center;
}

.priceP, .totalPriceP,.hours>p {
    margin-bottom: 0px !important;
    align-self: start;
}



.toursMainDiv img{
    width: 100%;
    max-width: 300px;
    min-height: 220px;
    max-height:30vh;
    object-fit: cover;
    border-radius: 15px;
   justify-self: center;
}

.toursMainMainDiv hr{
    width: 100%;
    color: #0E6E84;
   
}

.hours p {
    padding-inline: 15px;
    padding-block: 2px;
    background-color: #0E6E84;
    color: white;
    border: #fff 1px solid;
  }
  
  .hours p:first-child {
    border-radius: 15px 0px 0px 15px !important; /* First paragraph */
  }
  
  .hours p:last-child{
    border-radius: 0px 15px 15px 0px;
}
  
  .hours p:only-child{
    border-radius: 15px !important;
}




/*icons*/

.imgPDiv p{
    margin-bottom: 0px;
}

.tourDetIcons{
    width:15px !important;
    min-height: 15px !important;
    max-height: 15px !important;
    object-fit: scale-down !important;
    border-radius: 0px !important;
    /*filter: invert(29%) sepia(41%) saturate(2518%) hue-rotate(162deg) brightness(90%) contrast(89%);*/
}

.infoIcon{
    width: 25px !important;
    min-height: 25px !important;
    max-height: 25px !important;
    object-fit: scale-down !important;
    border-radius: 0px !important;
    filter: invert(29%) sepia(41%) saturate(2518%) hue-rotate(162deg) brightness(90%) contrast(89%);    
}


/*media*/
@media only screen and (max-width: 992px)  {
    .toursMainDiv img{
        width: 100%;
        max-width: none;
        min-height: 220px;
    }
    .toursMainDiv p:not(.toursHours){
        font-size: 15px;
        text-align: start;
    }
    .priceP, .totalPricePF ,.hours>p {
        align-self: center ;
    }
    .toursMainDiv .bookLinks {
        text-align: center;
      }
}