.tourFormHolder {
    position: relative;
    color: white;
    background-color: #0E6E84;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    border-radius: 15px;
  }
  
  .tourFormHolder .imgTitleHolder {
    border-left: 2px solid white;
  }
  
  .tourFormHolder .imgTitleHolder img {
    width: 60px;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(145deg) brightness(103%) contrast(101%);
  }
  
  .tourFormHolder .imgTitleHolder h3 {
    margin-bottom: 0px;
    align-self: center;
  }
  

.searchImg{
    height: 19px !important;
    width:30px !important;
}

.searchBtn{
    height: 50%;
    border-radius: 0px 15px 15px 0px;
    background-color: white;
}

.inputDatePeople{
    border: none;
    border-left: 1px #0E6E84 solid;
    padding-block: 4px;
    padding-inline:5px;
    font-size: 16px;
    font-size: 15px;
    color: rgb(110, 110, 110) !important;
}


.datepicker {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* ensures padding doesn't affect width */
    background-color: #f0f0f0;
    font-family: inherit; /* inherit font from parent element */
  }
  
  .datepicker:focus {
    border: 1px solid #007bff;
    outline: none; /* remove default outline on focus */
  }

.myList{
    background-color: white;
    color: black;
    cursor: pointer;
}

.myList >div {
    display: none;
    position: absolute;
    transform: translate(-14px,8px);
}



.activeList {
    width: fit-content;
    display: flex !important;
    gap: 7px;
    flex-direction: column;
    transform: translateY(5px);
    background-color: white !important;
    border: 2px solid #0E6E84;
    border-radius: 0px 0px 10px 10px;
  }
  
  .activeList p {
    margin-bottom: 0px;
    align-self: center;
  }
  
  .activeList input {
    width: 60px;
  }
  
  .activeList button {
    width: 40px;
  }
  
  .activeList hr {
    width: 100%;
    color: #0E6E84;
    border: 1px solid #0E6E84;
    margin-block: 0px;
  }
  


