.mainFormDiv{
   background-color: rgba(14, 110, 132,0.9)
}
.mainFormDiv .imgTitleHolder{
    border-left: 2px solid white;
}

.mainFormDiv .imgTitleHolder img{
    width: 80px;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(145deg) brightness(103%) contrast(101%);
}

p{
    margin-bottom: 0px;
}

.subimg{
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
.backgroundImg{
    position: absolute;
    width: 100%;
    height: 90vh !important;
    filter: blur(10px);
    object-fit: cover;
}

.basicP{
    font-size: 20px;
}

.subLinks{
    color: white;
}

hr{
    width:100%;
}

.homeLink{
    color: black;
    align-self: center;
    text-decoration: none;
    background-color: white;
    padding-inline: 15px;
    padding-block: 5px;
    border-radius: 15px;
}