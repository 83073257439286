nav {
    top: 0px;
    display: block !important;
    position: sticky !important;
    z-index: 101;
    background-color: #fff;
    transition: 500ms;
  }
  
  nav button {
    width: fit-content;
    background-color: #fff;
    transition: 500ms;
  }
  
  nav button img {
    border-radius: 50%;
  }
  


.scrolled {
    background-color: rgb(14, 110, 132) !important; /* Example scrolled background color */
    transition: 500ms;
  }
  
  .scrolled .logoImage {
    filter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(0%) hue-rotate(25deg) brightness(101%) contrast(106%);
    transition: 500ms;
  }
  
  .scrolled button {
    width: fit-content;
    background-color: #0e6e84;
    transition: 500ms;
  }
  
  .scrolled button img {
    border-radius: 50%;
  }
  
  .scrolled a {
    color: #fff !important; /* Example scrolled text color */
    transition: 500ms;
  }
  
  .scrolled .dropdown-menu {
    background-color: rgba(14, 110, 132, 0.8) !important;
    border: 1px #fff solid;
  }
  
  .scrolled .dropdown-menu li:hover a {
    color: rgb(14, 110, 132) !important;
  }
  

.navbar-brand
{
    margin-right: 0%;
}

.dropdown-menu{
    z-index: 101;
}


.navbar-collapse{
    flex-grow: 0;
    padding-right: 4% !important;
}

ul li a{
    color: rgb(14,110,132) !important;
    font-size: 20px !important;
    padding-inline: 31px !important;
    top: 0px !important;
}

.logoImage{
    
    width: 55px;
    position: relative;
    bottom: 4px;
}

.logoText{
    position: relative;
    top: 3px;
}

@media only screen and (max-width: 1315px){
  ul li a{
    font-size: 15px !important;
    
}
}

@media only screen and (max-width: 992px) {
    .navbar-toggler{
        width:auto;
    }
}

@media only screen and (max-width: 600px) {
    .logoImage{
        width: 45px;
    }
    
    .logoText{
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 300px) {
    .logoImage{
        display: none;
    }
    
    .navbar-toggler{
        left: 20px !important;
        position: relative;
    }

}
