.AboutUsSection{
    display: flex;
    flex-direction: column;
    height: auto;
    background-color:rgba(14,110,132, 0.9);
}

.aboutUsText{
    padding-inline: 5%;
    text-align: left;
    color: white;
}

.aboutUsTitle{
    font-size: 40px;
}

.partners{
    border-left: 2px white solid;
    color: white;
    
}

.partners h4{
    font-size: 33px !important;
    text-align: start;
    padding-left: 2%;
}

.certifications{
    align-self: end;
    height: auto;
    border-radius: 15px 0px 0px 15px;
    display: flex !important;
    flex-direction: column;
}

.partnersImages{
    width:120px;
   height:50px;
   border-radius: 15px;
    object-fit:scale-down;
    padding-inline: 10px;
    background-color: white;
   
}

.image-holder{
    position: relative;
    gap: 35px;
}
/************************************************/
/************************************************/
/*********************CERTIFICATION SECTION***************************/
/************************************************/

.certifications img{
    width:120px;
    height:90px;
    border-radius: 15px;
    object-fit:scale-down;

}

.irfImage{
    width: 80px !important;
    padding-block: 10px;
}

.wildernessImage{
    width: 250px !important;
    padding-block: 23px;
}

.certifications  h3{
    font-size: 27px;
}

.certifications  h3 p{
    margin-top: 0px;
    font-size: 19px;
    font-weight: 400;
}



.certifications > div > div{
    justify-content: center;
    flex-direction: column !important;
}

.certifications > h2 {
    text-align: start;
    border-left: 3px rgba(14,110,132, 0.9) solid;
    margin-left: 1%;
    margin-top: 1%;
    padding-left: 1%;
    padding-bottom: 0.1%;
    display: block;
}


/************************************************/
/************************************************/
/*********************RESPONSIVENESS SECTION***************************/
/************************************************/


@media only screen and (max-width: 992px) {
    
    .partners{
        border-left: none;
    }

    .partnersImages{
        width:30%;
       height:50px;
       border-radius: 15px;
        object-fit:scale-down;
        padding-inline: 10px;
        padding-block: 10px;
        background-color: white;
       
    } 

    .image-holder{
        gap: 15px;
    }

    .certifications{
        display: none !important;
    }

}