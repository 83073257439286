.pickupMainDiv {
    position: relative;

    left: 50%;
    transform: translateX(-50%);
  }
  

  .toursButtHolder{
    height: 40px;
  }

  .active-button{
    background-color: rgb(14, 110, 132);
    color: white;
    border: none;
    padding-block: 8px;
    padding-inline: 20px;
    border-radius: 15px 15px 0px 0px;
    transition: 500ms;
  }

  .inactive-button{
    background-color: white;
    color: black;
    border: rgb(14, 110, 132) 1px solid;
    padding-block: 4px;
    padding-inline: 20px;
    border-radius: 15px 15px 0px 0px;
    transition: 500ms;
  }
  
  .pickUpHolder {
    background-color:white;
    color: black;
    border-radius: 0px 0px 15px 15px;
  }
  
  .placesLink{
    color: black;
  }

  .pickUpHolder h2, .pickUpHolder h3 {
    text-align: start;
    border-left: black 2px solid;
    padding-left: 2%;
  }
  .pickUpHolder h3{
    margin-left: 2%;
  }
  
  .pickUpHolder li {
    text-align: start;
  }
  
  .pickUpHolder p {
    margin-bottom: 0px;
  }
  
  .titleHolder {
    background-color: rgb(14, 110, 132);
    border-radius: 15px 15px 0px 0px;
    color: white;
  }
  
  .titleHolder img {
    border-left: black 2px solid;
  }
  
  .titleHolder p {
    margin-bottom: 0px;
  }
  

.titleHolder p:first-child{
    font-size: 35px;
    font-weight:700;
}

.pickUpHolder::-webkit-scrollbar {
    width: 10px; /* Change width for horizontal scrollbar, height for vertical */
  }
  
  /* Style the scrollbar track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background for the track */
    border-radius: 0px 15px 15px 0px;
  }
  
  /* Style the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Darker color for the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  /* Style the thumb when hovering over the scrollbar */
  ::-webkit-scrollbar-thumb:hover {
    background: #666; /* Darken the thumb on hover */
  }

  @media only screen and (max-width: 768px) {

    .titleHolder p:first-child{
        font-size: 20px;
        font-weight:700;
    }
  }