.bg-primary{
    background-color: #5abbd1bd !important;
}

.lowest-part{
    background-color: #0e6e84 !important;
    --bs-gutter-x: none !important;
}

.footLink{
    color:white;
    text-decoration: none;
}

.copyrights{
    margin-top: auto;
    margin-bottom: auto;
}

hr{
    width: 40%;
    position: relative;
    margin-inline: auto;
    opacity: 1;
    
}

.social{
    filter: invert(1);
    margin-inline: 5px;
    width: 20px;
}

h5{
    margin-bottom: 10px;
}

.footerInnerDiv{
    text-align: center;
}

@media only screen and (max-width: 992px){
    .footerInnerDiv{
        text-align: start;
    }
}