.cardDiv{
    position: relative;
    bottom: 16vh;
}
.logoImg img {
    width: 80px;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(145deg) brightness(103%) contrast(101%);
  }

  .cardHr{
    width: 100%;
    margin-block: 0px;
    color: white;
  }
  .cardDet{
    background-color: rgb(14, 110, 132);
    color: white;
    border-radius: 0px 0px 15px 15px;
  }

  .boldLi{
    font-weight: 800;
  }