

.imgScheduleHolder .scheduleHeroImg{
    height: 70vh;
    width: 100%;
    object-fit: cover;
}


@media only screen and (max-width: 768px) {
    .imgScheduleHolder{
       
        margin-bottom: 5%;
    }

    .imgScheduleHolder .scheduleHeroImg{
        
        width: 100%;
        object-fit: cover;
    }
  }