


.mainCarousel {
    width: 100%; /* Adjust width as needed */
    position: relative;
    overflow: hidden; /* Clip content within each slide */
  }
  
  .slides {
    display: flex;
    height: 25vh;
    width: fit-content; /* Dynamic width based on content */
    /*width: calc(400%);*/ /* 100% for each of the 4 visible slides */
    animation: slide 20s infinite linear alternate; /* Adjust animation duration as desired */
    padding-left: 4px;
    --item-width: 25%;
  }
  
  .slides li {
    list-style: none;
    width: 25%; /* Replace with your desired fixed width */
    flex: 0 0 auto; /* Prevent items from shrinking beyond content width */
    margin: 0 0px; /* Add spacing between items (adjust as needed) */
    overflow: hidden; /* Clip content within each slide */
    white-space: nowrap;
    --item-width: 25%;
  }
  
  .slides li img {
    width: 100%;
    height: auto; /* Maintain image aspect ratio */
    object-fit: cover;
  }
  
  @keyframes slide {
    from { transform: translateX(0%); }
    to { transform: translateX(calc(100% - (var(--item-width) * var(--num-images)))); }
  }

  .mainCarousel:hover .slides {
    animation-play-state: paused; /* Pause animation on hover */
  }


  @media only screen and (max-width: 992px) {
    .slides {
      --item-width: 50%;
    }
    .slides li {
      width: 50%; /* Replace with your desired fixed width */
      --item-width: 50%;
    }
  }
  


