.cardsHolder{
    justify-content: space-around;
    margin-bottom: 5%;
}

.cardsDiv{
    max-width: none;
    display: flex;
    justify-content: center;
}

.mainCard{
    height: 50vh;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    padding-inline: 0px !important;
}


.details{
    display: flex;
    flex-direction: column;
    position: relative;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(255, 255, 255,0.6) !important;
    z-index: 100;
    border-radius: 12px 12px 12px 12px;
    height: 47%;
}

/* Styles for elements with a child element of class ".moreInfoButt" */
a:has(.moreInfoButt) {
    width: 50%;
    max-width: 100%;
  }
  
  /* Styles for buttons within the element with class ".moreInfoButt" */
  a:has(.moreInfoButt) button {
    font-size: 14px;
    width: 100%;
    background-color: rgb(14, 110, 132);
    padding-block: 9px;
    border: solid 1px white;
    border-radius: 10px;
    color: rgba(255, 255, 255);
  }
  


.bookNowButt{
    width: 50%;
    font-size: 14px;
    background-color:rgb(14,110,132);
    padding-block: 9px;
    border: solid 1px white;
    border-radius: 10px;
    color: rgba(255, 255, 255);
    max-width: 100%;
}

.tourTitle{
    position: relative;
    margin: 0px;
    color: rgba(255, 255, 255);
    top:10%;
}


/*ta background na ginoun apla images*/
.firstCrd{
    background-image:  linear-gradient(rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5)), url(./../images/littleAcheron/litleAcheron.webp);   
}

.secondCrd{
    background-image: linear-gradient(rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.5)), url(./../images/odyssey/OddysseyPhoto.webp);
    background-size: 1000px;
    background-position-y: bottom, 100%;
}

.thirdCrd{
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5)), url(./../images/acheronEscape/ExtendedTourImage.webp);
}