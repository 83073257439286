
.hero-div{
    position: relative;
    z-index: 0;
    height: 90vh;
    margin-bottom: 5%;
}

h1{
    position:absolute;
    text-align: center;
    color: white;
    font-size: 55px;
    font-weight: bold;
    bottom: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.bookButt{
    font-size: 18px;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    border: 2px solid white;
    padding: 10px 40px;
    background-color: rgba(14,110,132, 0.6);
    border-radius: 10px;
    color: white;
    text-decoration: none;
}

.bookButt div{
    position: absolute;
    width: 0%;
    background-color: white;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: -1;
    border-radius: 10px 100% 100% 10px;

    transition: 200ms linear;
}

.bookButt:hover{
    color: rgb(14,110,132) !important;
    font-weight: 700;
    transition: 800ms linear;
  
}

.bookButt:hover div{
    width: 100%;
    transition: 200ms linear;
    border-radius: 7px;
}

.brandName{
    font-weight: bold;
}

  
  .mobile-photo {
    display: block; /* Show photo on mobiles */
      position: relative;
    width: 100%;
    height: 100%;
    object-position: 90%;
    -o-object-fit: cover;
    object-fit: cover;
    filter: brightness(0.7);
    
  }
  
  @media (max-width: 767px) { /* Adjust breakpoint as needed */
    .mobile-photo {
      display: block; /* Show photo on mobiles */
      position: relative;
    width: 100%;
    height: 100%;
    object-position: 90%;
    -o-object-fit: cover;
    object-fit: cover;
    filter: brightness(0.7);
    }
  }
  

@media only screen and (max-width: 992px) {
    h1{
        top: 32%;
        width: 100%;
        font-size: 30px;
    }
}
