*[id] {
  scroll-margin-top: -200vh; /* Adjust this value as needed */
  scroll-behavior: smooth;
}

.mainPage:not(.footerInnerDiv) {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  

  .carText{
    font-size: 35px;
    margin-top:5%;
  }
  
  .steps img {
    width: 95%;
    object-fit: contain;
  }
  
  .steps button {
    width: auto;
    background-color: #0e6e84e6;
    padding: 10px 40px;
    border: 2px solid white;
    border-radius: 10px;
    font-size: 18px;
  }
  
  .steps button a {
    color: white;
    text-decoration: none;
  }

  .pickupSchedule{
    color: aliceblue;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .cardsH2{
    margin-bottom: 5%;
    font-size: 40px;
  }
  
  .toursWord{
    color: #5abbd1bd;
  }

  .map{
    width:100%
  }

  .dMfzQA{
    margin-top: 10%;
  }