/* Styles for the image list container */
.listForImg {
    height: 48%;
    list-style-type: none;
    position: relative;
    transition: .5s;
  }
  
  .listForImg img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .listForImg p {
    position: absolute;
    display: inline-block;
    height: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    text-wrap: wrap;
  }
  
  /* Show caption on hover */
  .listForImg:hover p {
    opacity: 1 !important;
  }
  
  /* Styles for the image container with scroll */
  .ulHolder {
    position: relative;
    height: 600px;
    padding-inline: 10px;
    overflow: auto;
  }
  
  .ulHolder ul {
    padding-left: 0px;
    overflow: auto;
    scroll-behavior: smooth;
  }
  
  .ulHolder ul::-webkit-scrollbar {
    display: none;
  }
  
  /* Styles for the scroll buttons */
  .ulHolder button {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-90%);
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 0px;
    border: none;
    border: 1px white solid;
    transition: 500ms;
  }
  
  .ulHolder button:hover {
    background-color: white;
    color: rgba(0, 0, 0, 1);
    transition: 500ms;
  }
  
  .ulHolder .nextButton {
    right: 15px;
  }
  
  .ulHolder .prevButton {
    left: 15px;
  }
  
  /* Media query for screens less than 992px */
  @media only screen and (max-width: 992px) {
    .ulHolder {
      height: 70vh;
    }
  }
  