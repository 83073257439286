.carousel{
    display: none;
}


@media only screen and (max-width: 992px){
    .carousel {
        background-color: white;
        display: flex !important;
        flex-direction: column !important;
        height: auto !important;
        flex-wrap: wrap !important;
      }
      
      .carousel h3 {
        color: black;
      }
      
      .carousel-control-next span {
        background-color: black !important;
      }
      
      .carousel-control-prev span {
        background-color: black !important;
      }
        
        .carousel-caption{
            position: relative;
            right: 0px;
            left: 0px;
            bottom: 0px;
        }

        .carousel p{
            color: black  !important;
            display: inline-block;
        }


        .carousel img{
            margin-top: 3px;
            width:120px;
            height:90px;
            border-radius: 15px;
            object-fit:scale-down;
        
        }
        
        
        .irfImage{
            width: 80px !important;
            padding-block: 10px;
        }
        
        .wildernessImage{
            width: 250px !important;
            padding-block: 23px;
        }
}