*[id] {
    scroll-margin-top: 15vh; /* Adjust this value as needed */
  }

.mainFormDiv{
    position: relative;
    top: 150px;
    background-color: rgba(14, 110, 132,0.9);
    color: white;
    border-radius: 20px;
    z-index: 0;
}

.imgDetHolder {
    border-left: 2px solid white;
  }
  
  .imgDetHolder img {
    width: 80px;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(145deg) brightness(103%) contrast(101%);
  }
  
  .imgDetHolder p {
    margin-bottom: 0px;
  }

.myBtn{
    color: #0E6E84 !important;
    background-color: #fff !important;
    border-radius: 10px;
    padding-block: 5px;
    padding-inline: 10px;
    border: none;
}

.disabledButton{
    background-color: grey;
    color: #cccaca;
    border-radius: 10px;
    padding-block: 5px;
    padding-inline: 10px;
    border: none;
}

.contactFormImg{
    padding-block: 20px;
    background-image: url(../images/acheronEscape/joshua_kai_famillia.webp);
    background-size: cover;
    margin-bottom: 12%;
}


.noDisplay{
  display: none;
}


  
  /* Styles for the first child element */
  .clockInput .theHours {
    border-radius: 15px;
  }
  
  




/* Target the number input element */
input[type="number"] {
    /* Hide the appearance of the spin buttons (arrows) */
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  
    /* Reset margins for older Chrome versions */
    margin: 0;
  }
  
  /* Target specific browser prefixes for spin buttons */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    /* Hide the spin buttons */
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Target Firefox specific selector */
  input[type="number"]::-moz-number-spinbutton {
    /* Hide the spin button */
    -moz-appearance: none;
  }






@media only screen and (max-width: 1076px){
    .mainFormDiv{
        position: relative;
        top: 0px;
    }

    .contactFormImg{
        margin-bottom: 0%;
    }
}

