*[id] {
    scroll-margin-top: 15vh; /* Adjust this value as needed */
}


.toursImg{
    height: 70vh;
    background-image: url(./../images/acheronEscape/joshua_kai_famillia.webp);
    background-size: cover;
    background-position: 40%;
}

.tourCardHolder{
    gap:15px;
}


@media only screen and (max-width: 768px){
    .toursImg{
    height: 30vh;
    }
}
